'use strict';

angular.module('cpformplastApp')
  .directive('searchtable', () => ({
    templateUrl: 'components/search-table/search-table.html',
    restrict: 'E',
    controller: 'SearchTableController',
    controllerAs: 'searchtable',
    scope: {
      tableStructure: '=tableStructure',
      objects: '=objects',
      buttons: '=buttons',
      rowClick: '=rowClick',
      archiveMode: '=archiveMode'
    }
  }));
